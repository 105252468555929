import React from "react"
import { Link } from "gatsby"
import Zoom from "react-medium-image-zoom"
import "react-medium-image-zoom/dist/styles.css"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Company from "../components/Company"

const MountRosinPage = () => (
  <Layout showOnlyHome={true}>
    <SEO title="Mount Rosin & Turpentine Industries Pvt. Ltd." />
    <Company
      pdf={[
        {
          title: "Company profile",
          src: "/company/document/rosin.pdf",
        },
      ]}
      title="Mount Rosin & Turpentine Industries Pvt. Ltd."
      phone="+977 – 71-560555, 562722"
      email="Email: info@gajurmukhiherbal.com "
    >
      <div className="images">
        <Zoom>
          <img src="/company/rosin.jpg" />
        </Zoom>
        <Zoom>
          <img src="/company/rosin1.jpg" />
        </Zoom>
      </div>
      <div className="content">
        <h5>Company Detail</h5>
        <p>
          Collection of Khoto (Products We Get From Pine Trees). Processing of
          Khoto to Make Rosin & Turpentine. • Exports of Rosin & Turpentine in
          India as well as third countries.
        </p>
        <h5>Objective</h5>
        <ul>
          <li>Collection of Khoto (Products We Get From Pine Trees).</li>
          <li>Processing of Khoto to Make Rosin &amp; Turpentine.</li>
          <li>
            Exports of Rosin &amp; Turpentine in India as well as third
            countries.
          </li>
        </ul>
        <p>
          OCR Registration No.: 46743/063/064 <br />
          PAN/VAT No.: 302631256 <br />
          Year of Establishment: 2007
          <br />
          Corporate Address: Madhabaliya ward no.- 9, Rupandehi, Bhairahawa,
          Nepal
        </p>
        <h5> Key Persons: Mr. Basanta Rokka Chhetri </h5>
        <ul>
          <li>Mr. Kumar Rokka Chhetri (Chairman)</li>
          <li>Mr. Basanta Rokka Chhetri (Director)</li>
          <li>Mr. Bharat Bdr Rokka Chhetri (Director)</li>
          <li>Mr. Ishowar Bdr Rokka Chhetri (Director)</li>
          <li>Mr. Deepak Rokka (Director)</li>
        </ul>
      </div>
      <div className="map">
        <iframe
          id="map-iframe"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3536.815707537663!2d83.48781281505904!3d27.56822948284983!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39969b46b9c2e04f%3A0xf311c0748a566267!2sMount%20Rosin%20%26%20Turpentine%20Industries%20Pvt.%20Ltd.!5e0!3m2!1sen!2snp!4v1585976562930!5m2!1sen!2snp"
          frameborder="0"
          allowfullscreen=""
        ></iframe>
      </div>
    </Company>
  </Layout>
)

export default MountRosinPage
